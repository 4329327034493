class RequestManager {
    constructor() {
      this.controllers = new Set();
    }
  
    createController() {
      const controller = new AbortController();
      this.controllers.add(controller);
      return controller;
    }
  
    abortAll() {
      this.controllers.forEach((controller) => controller.abort());
      this.controllers.clear();
    }
  }
  
export default new RequestManager();